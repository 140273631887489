import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ContactHeadline = () => {
    return (
        <div className='headlines m-0 p-0'>
            <div className='p-0 m-0'>
                <div className='p-0 m-0 has-text-weight-bold has-text-white loading-pulse'>
                    <p className='h1 is-style-sentence-case'>Contact</p>
                </div>
            </div>
        </div>
    );
};

export default ContactHeadline;