import React from "react";

const Footer = () => {

    return (
      <div className='m-0 p-0 columns'>
        <div className='m-0 px-5 column is-12 is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-space-between is-align-content-center'>
          <p className='microText'>Copyright © 2025 Guava Ice Cream, LLC.</p>
          <p className='microText'>Back to top</p>
        </div>
      </div>
    );
} ;

export default Footer;