import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Homepage from '../components/Homepage';
import About from '../components/About';
import Contact from '../components/Contact';
import Clients from '../components/Clients';
import Cases from '../components/Cases';
import Experiments from '../components/Experiments';
import Segmind from '../components/Segmind';

const Routers = () => {
    return (
        <Routes>
            <Route path="/" element={
                <PublicRoute>
                    <Homepage />
                </PublicRoute>} 
            />
            <Route path="/clients" element={
                <PublicRoute>
                    <Clients />
                </PublicRoute>} 
            />
            <Route path="/about" element={
                <PublicRoute>
                    <About />
                </PublicRoute>} 
            />
            <Route path="/contact" element={
                <PublicRoute>
                    <Contact />
                </PublicRoute>} 
            />
            <Route path="/experiments" element={
                <PublicRoute>
                    <Experiments />
                </PublicRoute>} 
            />
            <Route path="/cases" element={
                <PublicRoute>
                    <Cases />
                </PublicRoute>} 
            />
            <Route path="/segmind" element={
                <PublicRoute>
                    <Segmind />
                </PublicRoute>} 
            />
        </Routes>
    )
}

const PublicRoute = ({ children }) => {
    return children;
}

const PrivateRoute = ({ children }) => {
    const isAuthenticated = checkIfUserIsAuthenticated();
    return isAuthenticated ? children : <Navigate to="/login" />;
}

// Placeholder function - replace with actual authentication logic
const checkIfUserIsAuthenticated = () => {
    // This should be replaced with your actual authentication check
    return false; // Always returns false for now
}

export default Routers;