import React, { useEffect, useState, Suspense  } from 'react';
import HomeStatement from './HomeStatement';
import HomeRecentEngagements from './HomeRecentEngagements';
import Footer from './Footer';
import HomeSpotlightSegmind from './HomeSpotlightSegmind';

const NavPublic = React.lazy(() => import('./NavPublic'));

function Homepage() {
  const [engagements, setEngagements] = useState([]);
  const [filteredEngagements, setFilteredEngagements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEngagements = async () => {
      const isDevelopment = process.env.NODE_ENV === 'development';
      const apiUrl = isDevelopment
        ? 'http://localhost:5555/api/engagements'
        : '/api/engagements';

      try {
        setIsLoading(true);
        const response = await fetch(apiUrl);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        setEngagements(data);
        setFilteredEngagements(data);
      } catch (error) {
        console.error('Error fetching engagements:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEngagements();
  }, []);

  if (isLoading) {
    return <div className="container p-6">Loading...</div>;
  }

  if (error) {
    return <div className="container p-6 has-text-danger">Error: {error}</div>;
  }

  return (
    <>   
      <section className="section m-0 p-0" data-theme="dark">
        <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-space-between">
          <div className="is-flex is-flex-direction-column">
            {/* Navigation */}
            <div className="p-6 m-0">
              <Suspense fallback={<div>Loading...</div>}>
                <NavPublic colorView="light" />
              </Suspense>
            </div>
          </div>
        </div>
      </section>
      
      <section className="section m-0 px-0 pt-6 pb-5">
        <div className="m-0 p-0">
          <HomeStatement />
        </div>
      </section>

      <section className="section m-0 px-0 py-6">
        <div className='is-flex is-flex-direction-row p-0 m-0'>
          <p className='container is-size-3 has-text-weight-bold colorBlack2 has-text-left'>Recent engagements</p>
        </div>
        <div className="m-0 p-0">
          <div className="columns is-12-mobile is-6-tablet is-3-desktop m-0 p-0">
          {filteredEngagements.map((engagement) => (
            <div key={engagement._id} className="column p-4">
              <HomeRecentEngagements engagement={engagement} />
            </div>
          ))}
          </div>
        </div>
      </section>

      <section className='section m-0 px-0 py-6'>
        <HomeSpotlightSegmind />
      </section>
      
      <section className='section m-0 p-0'>
        <Footer />
      </section>
    </>
  );
}

export default Homepage;