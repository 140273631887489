import React, { useEffect, useState, Suspense } from 'react';
import ContactHeadline from './ContactHeadline';

const NavPublic = React.lazy(() => import('./NavPublic'));

const Sizzle = () => {

    const [videoSrc, setVideoSrc] = useState('');

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    setVideoSrc(isMobile ? '/videos/guava-sizzle-home-2-mobile.mp4' : '/videos/guava-sizzle-home-2-desktop.mp4');
  }, []);

    return (
      <>
        <div className="videoContainer">
            <video src={videoSrc} autoPlay muted loop type="video/mp4"></video>
        </div>
        <div className='px-6 m-0'>
          <Suspense fallback={<div>Loading...</div>}>
              <NavPublic colorView="dark" />
          </Suspense>
        </div>
        <div className='container'>
          <div className='m-0 p-0'>
            <ContactHeadline /> 
          </div>
        </div>
      </>
    );
};

export default Sizzle;